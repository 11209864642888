<template>
  <div :style="`min-height: ${height}px`" id="SelectShipment">

    <div class="deli-cont" v-for="(item, index) in productList" :key="index">
      <div class="list-tit pink-bg">
        <span class="tit-left">{{ (item.deliveryMethod === 1 ? '中国产地(国内发)' : '选择自用发货') }}</span>
        <span class="tit-right">共计: <span>{{ item.totalCount }}件</span></span>
      </div>
      <div class="list" v-for="(value, key) in item.list" :key="key">
        <div v-if="value.title" style="text-align: left;box-sizing: border-box;padding: 10px;border-bottom: 1px solid #f1f1f1;font-weight: bold;font-size: 13px">
          {{ value.title }}
        </div>
        <div v-for="(item2, idx) in value.child" class="goods" :key="idx">
          <van-image fit="contain" :src="item2.productUrl" alt="" />
          <div style="position: relative" class="goods_info">
            <div class="goods_name">{{ item2.productName }}</div>
            <div class="goods_specifications">{{ item2.productContent }}</div>
            <div class="goods_num">下单商品数量：{{ item2.productCount }}</div>
            <div class="goods_num" v-if="item.deliveryMethod === 2">店主起提支数：{{ item2.goodsQuantity }}</div>
            <div class="goods_control">自用发货</div>
            <!-- 占位div，严禁删除↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ -->
            <div style="opacity: 0" class="goods_control">占位div，严禁删除</div>
            <div class="num" style="position:absolute;bottom:0;color: #ff98a0;" v-if="item2.count <= 0">*未选中数量将全部存入库存
            </div>
            <div class="stepper" v-if="item.deliveryMethod !== 1">
              <van-image @click="handleGoodsCount(item2, item.deliveryMethod, '-')" :style="item2.count > 0 ? 'opacity: 1' : 'opacity: 0'" class="control_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210727104112550.png"></van-image>
              <div class="num" style="color: #666;margin-left: 5px;margin-right: 5px" v-if="item2.count > 0">
                <input @input="goodsCountChange(item2)" class="count_input" v-model="item2.count" type="number">
              </div>
              <van-image @click="handleGoodsCount(item2, item.deliveryMethod, '+')" class="control_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210727104132691.png"></van-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <div @click="back" class="handle_btn handle_btn1">取消</div>
      <div @click="sure" class="handle_btn handle_btn2">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectShipment",
  data() {
    return {
      height: '',
      info: {},
      orderId: '',
      loading: false,
      usemyselfCount: 0,
      productList: []
    }
  },
  created() {
    this.height = window.innerHeight
    document.title = '选择自用发货'
    this.orderId = this.$route.query.orderId
    this.Init()
  },
  methods: {
    Init: function () {
      this.$toast.loading({ duration: 0 })
      this.post('/OrderGoods/Order/GetOrderProducts', { orderId: this.orderId || '100008627' }, true, 2).then(data => {
        let { products, goodsAdd, goodsPrizes, usemyselfCount, orderId } = data.response;
        this.orderId = orderId
        this.usemyselfCount = usemyselfCount
        let proList = [];
        this.setProductType("", 1, proList, products);
        this.setProductType("加价购商品", 2, proList, goodsAdd)
        this.setProductType("免费送商品", 3, proList, goodsPrizes);
        proList.forEach(item => {
          let count = 0;
          if (item.deliveryMethod === 1) {
            item.list.forEach(tt => {
              tt.child.forEach(good => count += good.productCount);
            })
          }
          item.totalCount = count;
        })
        proList.sort((item, item1) => {
          return item.deliveryMethod - item1.deliveryMethod;
        })
        this.productList = proList;
        this.$toast.clear()
        // console.log(this.info)
      })
    },
    setProductType(title, type, proList, list) {
      list.forEach(good => {
        good.count = good.deliveryMethod === 2 ? 0 : good.productCount;
        let item = proList.find(it => it.deliveryMethod === good.deliveryMethod);
        if (item) {
          let data = item.list.find(tt => tt.type === type);
          if (data) data.child.push(good);
          else item.list.push({ title, type, child: [good] });
        }
        else {
          proList.push({ deliveryMethod: good.deliveryMethod, list: [{ title, type, child: [good] }] });
        }
      })
    },
    goodsCountChange(e) {
      const num = String(e.count)
      if (num > e.productCount) {
        e.count = e.productCount
        this.$toast(`最多只能提取${e.productCount}件噢`)
      }
      if (num == "" || num < 0) e.count = 0;
      if (num.includes('.')) e.count = Math.floor(e.count)
      if (num[0] === '0') e.count = 0;
      if (num < e.goodsQuantity) e.count = e.goodsQuantity;
      this.calcCount();
    },
    handleGoodsCount: function (item, deliveryMethod, type) {
      if (deliveryMethod === 1) return;
      if (type == '-' && item.count == 0) return
      if (type == '+' && item.count == item.productCount) return this.$toast('已达商品最大数量');
      if (type == '-') {
        if (item.count === item.goodsQuantity) item.count = 0;
        else item.count--;
      } else {
        if (item.count === 0) {
          if (item.productCount < item.goodsQuantity) {
            this.$dialog.alert({
              title: "提示",
              message: "库存不足，请采购订货或联系客服"
            });
            return;
          }
          item.count = item.goodsQuantity;
        } else item.count++;
      }
      this.calcCount();
    },
    calcCount: function () {
      this.productList.forEach(item => {
        if (item.deliveryMethod !== 1) {
          let count = 0;
          item.list.forEach(it => {
            it.child.forEach(good => count += +good.count);
          });
          item.totalCount = count;
        }
      })
    },
    deWeightTwo: function (arr2) {
      let temp = []
      arr2.forEach(function (a) {
        var check = temp.every(function (b) {
          return a.productID !== b.productID;
        })
        check ? temp.push(a) : ''
      })
      return temp;
    },
    sure: function () {
      if (this.loading) return
      this.loading = true
      let arr = [];
      let tipsArr = []
      console.log(this.productList)
      this.productList.forEach(item => {
        item.list.forEach(it => {
          it.child.filter(tt => tt.count > 0).forEach(tt => {
            arr.push({ count: tt.count, productID: tt.productID, sourceType: tt.sourceType })
            if (tt.showTips) {
              tipsArr.push({ productID: tt.productID, showTips: tt.showTips })
            }
          })
        })
      })
      if (arr.length == 0) {
        this.$toast.fail('请选择商品')
        this.loading = false
      }
      if (tipsArr.length > 0) {
        let newArr = this.deWeightTwo(tipsArr);
        let tipsStr = []
        newArr.forEach(it => {
          tipsStr.push(it.showTips)
        })
        this.$dialog.confirm({
          title: '提示',
          message: tipsStr.join(';'),
          confirmButtonColor: '#FF98A0',
          cancelButtonText: '暂不提货',
          confirmButtonText: '我已知晓，继续提货'
        }).then(e => {
          this.usemyselfHint(arr)
        }).catch(() => this.loading = false)
      } else {
        this.usemyselfHint(arr)
      }
    },
    usemyselfHint: function (arr) {
      if (this.usemyselfCount > 0) {
        this.$dialog.confirm({
          title: '提示',
          message: `您已选择自提${this.usemyselfCount}件商品，本次操作将覆盖之前自提数据（重新选择自提商品及数量），是否确认操作 `,
          confirmButtonColor: '#FF98A0'
        }).then(e => {
          this.sureSubmit(arr)
        }).catch(() => this.loading = false)
      } else {
        this.$dialog.confirm({
          title: '提示',
          message: '确认提交发货吗？',
          confirmButtonColor: '#FF98A0'
        }).then(e => {
          this.sureSubmit(arr)
        }).catch(e => this.loading = false)
      }
    },
    sureSubmit: function (arr1) {
      this.$toast.loading({ duration: 0 })
      let arr = JSON.stringify(arr1)
      this.post('/OrderGoods/Order/SpitOrderProductsToRoomV2', {
        orderId: this.orderId,
        data: arr
      }, true, 2).then(data => {
        if (data.code) {
          this.$toast.success('提交成功')
          setTimeout(() => {
            return this.$router.push(`/SelectSuccess/${data.response.useCount}/${data.response.notUseCount}/${data.response.totalCount}/${data.response.orderNum}/${this.orderId}`)
          }, 1000)
        } else {
          this.$toast.fail(data.msg)
        }
        this.loading = false
      })
    },
    back() {
      // window.location.href = this.userHost + `/rolorweb/html/recharge/orderDetails.html?orderId=${this.orderId}&type=1&brandId=4&tabId=0&index=0`
      window.location.href = this.userHost + `/rolorweb/html/order/personal.html`
      // http://tuser.rolormd.com/rolorweb/html/order/personal.html
    }
  }
}
</script>

<style lang="scss">
#SelectShipment {
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  padding-bottom: 110px;

  .deli-cont {
    padding-top: 15px;

    .list-tit {
      margin-bottom: 1px;
      padding: 10px 15px;
      background-color: #fff;
      display: flex;
      border-radius: 15px 15px 0 0;
      justify-content: space-between;
      align-items: center;

      //border-bottom: 1px solid #f1f1f1;
      .tit-left {
        font-size: 12px;
        color: #666666;
      }

      .tit-right {
        font-size: 12px;
        color: #666666;

        span {
          color: #ff98a0;
        }
      }

      &.pink-bg {
        background-color: #fff5f6;
      }
    }
  }

  .list {
    overflow: hidden;

    background-color: #fff;

    .goods {
      display: flex;
      text-align: left;
      border-bottom: 1px solid #f1f1f1;
      width: 100%;
      /*justify-content: space-between;*/
      box-sizing: border-box;
      padding: 15px;
      align-items: center;
      flex: 1;

      .van-image {
        width: 125px;
        height: 125px;
        margin-right: 10px;
      }

      .goods_info {
        flex: 1;
      }

      .goods_name {
        font-size: 14px;
        color: #666666;
        /*min-width: 100%;*/
      }

      .count_input {
        width: 14px;
        text-align: center;
        border: none;
      }

      .goods_specifications {
        color: #666;
        font-size: 10px;
        margin-top: 5px;
      }

      .goods_num {
        margin-top: 5px;
        color: #b5b5b5;
        font-size: 10px;
      }

      .goods_control {
        margin-top: 5px;
        color: #b5b5b5;
        display: flex;
        font-size: 10px;
      }

      .stepper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 76px;
        position: absolute;
        right: -11px;
        bottom: -5px;

        &.disable {
          opacity: 0.6;
        }
      }

      .control_icon {
        width: 22px;
        height: 22px;
        margin-right: 0;
      }
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  .bottom_box {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 99;

    .handle_btn {
      width: 150px;
      height: 34px;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }

    .handle_btn1 {
      border: 1px solid #f0f0f0;
      color: #666666;
    }

    .handle_btn2 {
      background-color: #ff98a0;
      color: #fff;
    }
  }
}
</style>